; (function ($) {
    'use strict';


    //Inclure la lib slick-slider
    $(document).ready(function () {
        initSlickSlider();

        $(window).resize(function () {
            if ($(this).width() < 992 && $('.slick-initialized').length == 0)
                initSlickSlider();
        });


        $('.products-slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            prevArrow: $('.products-prev'),
            nextArrow: $('.products-next'),
            dots: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.team-slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            dots: false,
            prevArrow: $('.products-prev'),
            nextArrow: $('.products-next'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.testi-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            prevArrow: $('.testi-prev'),
            nextArrow: $('.testi-next'),
            dots: false
        });
    });

    function initSlickSlider() {
        $('.services-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: $('.services-prev'),
            nextArrow: $('.services-next'),
            dots: false,
            autoplay: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: "unslick"
                }]
        });
    }
}(jQuery));